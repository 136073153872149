@main-banner-text-color: #eee;

.main-header{
  color:@main-banner-text-color;
}

.main-banners-container{
  margin-top:45px;
  margin-bottom: 25px;
  @media only screen and (max-width: @screen-xs-max){
    margin-top:0;
    margin-bottom:16px;
  }

  .main-banner{
    display:flex;
    justify-content: space-around;
    align-items: center;
    height:300px;
    color:#fff;
    @media only screen and (max-width: @screen-xs-max){
      height:140px;
    }

    .main-banner-text{
      flex-grow: 2;
      padding: 0 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 250px;
      align-items: flex-start;
      @media only screen and (max-width: @screen-xs-max){
        height:100px;
        padding:0 12px;
      }

      span{
        font-size: 40px;
        font-weight:700;
        @media only screen and (max-width: @screen-xs-max){
          font-size: 18px;
        }
      }

      button{
        height:53px;
        background:none;
        font-size: 18px;
        font-weight:700;
        border:3px solid #fff;
        border-radius: 3px;
        padding:0 10px;
        @media only screen and (max-width: @screen-xs-max){
          height:initial;
          font-size:9px;
          border-width: 2px;
          border-radius: 0;
          padding:5px 10px;
        }
      }
    }
    .main-banner-image{
      flex-grow: 1;
      text-align: right;

      @media only screen and (max-width: @screen-xs-max) {
        img{
          max-width: 100%;
          max-height: 120px;
        }
      }
    }
  }

  .starlight-banner{
    background: linear-gradient(
            to left,
            #003974,
            #0083e6 150px,
            #072868 600px
    );

    .main-banner-image{
      padding-right: 35px;
      @media only screen and (max-width: @screen-xs-max) {
        padding-right: 12px;
      }
    }
  }
}

.main-banner-buttons-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: @screen-sm-max)
  {
    flex-wrap: wrap;
  }

  a{
    height: 100px;
    line-height: 100px;
    color: #fff;
    text-decoration: none;
    font-size: 36px;
    font-weight: 400;
    padding-left: 27px;
    background: #ED1C24;
    width: 33%;
    margin-right: 16px;
    cursor: pointer;
    @media only screen and (max-width: @screen-md-max){
      font-size: 24px;
      height: 75px;
      line-height: 75px;
    }
    @media only screen and (max-width: @screen-sm-max)
    {
      margin:0;
      width:48%;
      &#price_button{
        width:100%;
        background: #3279B7;
        margin-top:16px;
      }
    }
    @media only screen and (max-width: @screen-xs-max)
    {
      font-size: 12px;
      height: 35px;
      line-height: 35px;
      margin:0;
      width:49%;
      padding-left: 0;
      text-align: center;
      &#price_button{
        width:100%;
        background: #3279B7;
        margin-top: 8px;
      }
    }

    img{
      border:0;
      margin-top: -5px;
      @media only screen and (max-width: @screen-md-max){
        height:35px;
      }
      @media only screen and (max-width: @screen-xs-max){
        height:18px;
        margin-top: -2px;
      }
    }
  }

  .price-btn{
    margin-right: 0px !important;
  }
}

.main-advantages-line {
  @media only screen and (max-width: @screen-sm-max){
    display:none;
  }
  background: #E3E3E3;
  margin:45px 0;

  .main-advantages-container {
    display: flex;
    height:150px;
    justify-content: space-between;
    align-items: center;

    .main-advantage {
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 20%;
      margin-right: 30px;
      @media only screen and (max-width: @screen-md-max) and (min-width: @screen-md-min){
        font-size: 13px;
        line-height: 15px;
      }

      &:last-child{
        margin:0;
      }

      img{
        margin-right:15px;
      }
    }
  }
}
.main-banner-wrapper{
  .container-fluid();
  background:#000;
  .main-banner-content{
    .container();

    .main-form{
      
     // background: url(/images/snowflakes.png);
      
      .make-lg-column(4);
      .make-md-column(4);
      .make-sm-column(6);
      .make-xs-column(12);

      .main-form-header{
        margin:10px 0;
        font-size:1.5em;
        //color:@main-banner-text-color;
        color: #f00;
        font-weight:bold;
        text-align:center;
      }

      input{
        .form-control();
      }

      .form-group.has-error input{
        background: @error-input-bg;
      }

      label{
        color:@main-banner-text-color;
      }

    }

    .main-banner-image-wrapper{
      .make-lg-column(8);
      .make-md-column(8);
      .make-sm-column(6);
      .make-xs-column(12);
      text-align:center;

      img{
        width:100%;
        @media (min-width: @screen-lg-min)
        {
          max-height:292px;
        }
      }
    }
  }
}

.main-banner-image-cell{
  @media  (min-width: @screen-sm-min) {
      display: table-cell;
      vertical-align: middle;
      height: 292px;
  }
}

.main-form-button{
  padding:10px 20px;
  margin:20px 0;
  font-size:1.4em;
  font-family:Arial;
  font-weight:bold;
  //border-radius:5px;
  text-transform: uppercase;
  border:2px solid #ff7335;
  background:@danger-color;
  color:#fff;
}

.offer-header{
  font-size:1.8em;
  padding:0 30px;
  @media only screen and (max-width: @screen-xs-max){
    text-align:center;
    padding:0;
  }
}

.main-form-line{
  background: #E3E3E3;
  margin-bottom: 45px;
  @media only screen and (max-width: @screen-xs-max){
    margin:10px;
    border-radius: 3px;
  }

  .main-form-container{
    padding: 45px 150px;
    @media only screen and (max-width:@screen-sm-max) {
      padding: 0px 20px;
    }

    .main-form{
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .main-form-header{
        font-size: 27px;
        font-weight: 400;
        color:#ED1C24;
        margin:0;
        @media only screen and (max-width:@screen-xs-max){
          margin-top: 20px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
      }
      .main-form-description{
        font-size: 18px;
        font-weight: 400;
        margin:25px 0;
        @media only screen and (max-width:@screen-xs-max){
          font-size: 14px;
          line-height: 17px;
          text-align: center;
        }
      }
      .form-group{
        margin-bottom: 25px;
        @media only screen and (max-width:@screen-xs-max){
          margin-bottom: 10px;
        }
        input[type='text'], textarea{
          height:46px;
          width:100%;
          padding:15px;
          font-size: 14px;
          border: 1px solid #D9D9D9;
          @media only screen and (max-width:@screen-xs-max){
            height:33px;
            font-size: 12px;
          }
        }
      }

      .form-last-row{
        display: flex;
        align-items: stretch;
        flex-direction: row;
        @media only screen and (max-width:@screen-sm-max) {
          flex-direction: column;
        }

        &>.form-group{
          flex-grow:1;
        }

        textarea{
          height:100%;
        }

        .form-last-cell{
          width: 35%;
          padding: 0 15px;
          text-align: center;
          @media only screen and (max-width:@screen-sm-max) {
            width: 100%;
            padding: 0;
            text-align: left;
          }

          &>.form-group{
            font-size: 16px;
            color: #333;
            @media only screen and (max-width:@screen-xs-max){
              font-size: 12px;
              margin: 8px 0 16px 0;
            }

            input[type='checkbox']{
              width: 20px;
              height: 20px;
              border: 1px solid #D9D9D9;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: #fff;
              border-radius: 3px;
            }
            input[type="checkbox"]:after {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #fff;
              content: "✓" !important;
            }
            input[type="checkbox"]:checked:after {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #333;
              content: "✓" !important;
            }

          }
          button{
            width: 100%;
            height: 53px;
            border: 0;
            border-radius: 3px;
            background: #ED1C24;
            color: #fff;
            font-size: 18px;
            font-weight: 400;

            @media only screen and (max-width: @screen-sm-max){
              width:initial;
              height:initial;
              padding:10px;
            }
            @media only screen and (max-width: @screen-xs-max){
              font-size: 12px;
            }
          }
        }
      }

      .form-group.has-error input{
        border: 1px solid #ED1C24 !important;
      }
    }

  }
}

.main-product-offers-wrapper{
  .make-row();
  margin-top:40px;
  margin-bottom:40px;
    .product-offer{
      .make-xs-column(6);
      .make-sm-column(6);
      .make-md-column(3);
      .make-lg-column(3);
    }


  .product-offer{
    height:170px;
    text-decoration:none;
    text-align:center;
    @media only screen and (max-width: @screen-xs-max){
      padding:0 !important;
    }

    .offer-image{
      width:90%;
      max-width:145px;
    }

    .offer-text{
      display:block;
      text-align:center;
      color:#333;
      font-weight:bold;
      @media only screen and (max-width: @screen-xs-max) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.main-apps-and-news{
  .make-row();
  margin-bottom:40px;
}


.main-applications-wrapper{
  .make-lg-column(4);
  .make-md-column(4);

  .application-wrapper{
    text-align:center;
    margin-bottom:10px;


    .application{
      text-align:left;
      display:inline-block;
      text-decoration:none;
    }

    .application-image{
      width:90%;
      max-width:280px;
    }

    .application-header{
      font-size:1.5em;
      margin-bottom:10px;
      color:#333;
      text-decoration:none;
    }
  }
}

@media(max-width: @screen-md){
  .main-applications-wrapper{
    display:table;
    width:100%;
    .application-wrapper{
      display:table-cell;
      vertical-align:middle;
      text-align:middle;
    }
  }
}

.main-news-wrapper{
  //border:2px solid #ddd;
  .news-header{
    font-size: 27px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 400;
    color: #ED1C24;
  }
}

.main-text{
  text-align:justify;
}