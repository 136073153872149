footer{
  background:#ED1C24;
  color:#fff;
  padding-bottom:40px;
  position:absolute;
  bottom:0;
  width:100%;
  font-size:13px;
  font-weight:400;

  a{
    text-decoration: none;
    color:#fff;
    &:hover{
      color:#eee;
    }
  }
}
.footer-bottom{
  padding-top:5px;
  padding-right:5px;
  float:left;
  @media only screen and (max-width:@screen-sm-max)
  {
    width:420px;
  }
  @media only screen and(max-width: @screen-xs-max){
    width:100%;
    clear: both;
  }
}

.footer-section{

  &.products-section{
    max-width:802px;
    float:left;
  }
  &.common-footer-section{
    padding-left:5px;
    float: right;
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    .footer-common-col{
      width:50%;
      &:first-child{
        padding-right:5px;
      }
    }
    .footer-contacts-wrapper{
      width:100%;
    }
  }

  @media only screen and (max-width:@screen-md-max)
  {
    &.products-section{
      width:642px;
    }
    &.common-footer-section{
      width:298px;
    }
  }
  @media only screen and(max-width: @screen-xs-max)
  {
    &.common-footer-section{
      width: 100% !important;
      max-width: initial !important;
      padding-left: 0 !important;
    }
  }

  .section-header{
    padding:5px;
    border-bottom:1px solid #5B5F63;
  }

  .footer-nav{
    list-style:none;
    margin-left:5px;
    margin-right:10px;
    padding-left:0;
    display:inline-block;
    vertical-align:top;

  }

  .footer-product-categories{
    .category{
      display:inline-block;
      vertical-align:top;
      width:160px;
      margin-left:-4px;

      div{
        font-weight: 700;
      }

      h5{
        font-size:0.9em;
      }
      .footer-nav-item{
        font-size:10px;
        font-weight:300;
      }
    }
  }

  .footer-contacts-wrapper{
    margin:15px 0;
    .footer-contact{
      .contact-label{
        display: inline-block;
        width: 85px;
      }
    }
  }
}