.header-top{
  height: 53px;
  line-height:53px;
  font-size:16px;
  font-weight:400;
  background-color: #ED1C24;
  color: #FFFFFF;
  text-align: right;

  .header-phone{
    display:inline-block;
    img {
      margin-right:15px;
    }
  }

  .header-email{
    display:inline-block;
    margin-left:30px;
    img {
      margin-right:15px;
    }
  }
  a{
    color:#ffffff;
  }
}

.header-middle {
  display:flex;
  justify-content: space-between;
  align-items:center;
  height:105px;

  .main-logo-block{
    flex:1;
    .main-logo-wrapper {
      text-decoration: none;
      font-size: 10px;
      color: #000;
      @media (max-width: @screen-xs-max) {
        font-size: 8px;
      }

      .main-logo {
        width: 129px;
        height: 39px;
      }
    }
  }
  .main-search-wrapper{
    text-align:right;
    .main-search{
      padding: 0 40px 0 20px;
      width:316px;
      height:36px;
      border-radius:12px;
      outline:none;
      border:0;
      color:#000;
      font-weight:400;
      background-color: #f1f1f1;
      background-image: url(/images/lens.png);
      background-repeat: no-repeat;
      background-position: right 12px top 50%;
      .placeholder(#000000);
    }
  }

  .mobile-header-buttons{
    @media (max-width:@screen-xs-max)
    {
      display: block;
    }
    @media (min-width:@screen-sm-min)
    {
      display: none;
    }
    a{
      margin-left: 20px;
      height: 22px;
      display: inline-block;
    }
  }
}

.menu-btn, .menu-close-btn{
  cursor:pointer;
}


.mobile_header{
  display:none;
}

@media only screen and (min-width: @screen-sm-min) {
  .main-menu {
    background: #303030;
    height: 53px;


    .main-menu-items {
      @media (max-width: @screen-sm-max) {
        font-size: 14px;
      }
      @media (min-width: @screen-md-min) {
        font-size: 16px;
      }
      list-style: none;
      font-weight: 400;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .main-menu-item {
        a {
          color: #fff;
          display: block;
          text-decoration: none;
          height: 53px;
          line-height: 53px;
        }
      }
    }
  }
}

@media only screen and (max-width: @screen-xs-max) {
  .main-menu {
    display:none;
    background: #FFFFFF;
    height: 100%;
    width:100%;
    color: #2F2F2F;
    position: absolute;
    top:0;
    left:0;
    z-index:12;
    padding:35px;

    .main-menu-items {
      font-size: 16px;
      list-style: none;
      font-weight: 400;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;


      .main-menu-item {
        a {
          color: #303030;
          display: block;
          text-decoration: none;
          line-height: 33px;
        }
      }
    }

    .mobile-menu-contacts{
      margin-top:20px;
      a {
        display:block;
        font-size:16px;
        font-weight: 700;
        text-decoration: none;
        line-height:20px;
        margin-top:5px;
      }
      .roistat_phone {
        color:#2F2F2F;
      }
      .email{
        color:#ED1C24;
      }
    }
    .mobile-menu-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom:15px;
      padding-left: 15px;

      .mobile-search-wrapper{
        background: #f1f1f1;
        border-radius: 12px;
        padding-left: 12px;

        form {
          display: inline-block;
        }

        .mobile-search{
          padding: 0 12px;
          height:36px;
          border-radius:12px;
          outline:none;
          border:0;
          color:#000;
          font-weight:400;
          background-color: #f1f1f1;
          .placeholder(#000000);
        }

      }

    }
  }
}